
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'garage-container' }, [this.changeButton(function () {
            return _createElement('div', { 'className': 'cm_changeButton' }, [_createElement('svg', {
                    'className': 'cm_icon cm_icon-jeep',
                    'height': '40px',
                    'role': 'img',
                    'viewBox': '0 0 100 100',
                    'key': '340'
                }, _createElement('path', { 'd': 'm86.012 39.5-2.475 14.067c-0.348 1.98-2.222 5.724-6.817 5.724h-53.799c-2.009 0-6.022-1.194-6.833-5.718l-2.515-14.039-13.541 1.516v8.529h7.098v5.629h-2.032s-5.098-0.316-5.098 4.781v35.225s0 4.782 5.098 4.782h6.862s5.097 0.316 5.097-4.782v-13.579h35.619s3.024 2.813 6.563 2.954c5.519 0.221 8.141-4.13 8.141-4.13l15.414 3.848v10.907s0.739 4.782 5.837 4.782h6.122s5.1 0.316 5.1-4.782v-35.225s0-4.781-5.1-4.781h-2.024l0.072-5.629h7.076v-8.529l-13.865-1.55z' }), _createElement('path', { 'd': 'm90.393 35.878 0.014-10.44h-6.389v-9.496c0-3.47-2.822-6.293-6.294-6.293h-55.4c-3.47 0-6.294 2.823-6.294 6.293v9.485l-6.182-0.014-0.021 10.517 5.657 0.018c-0.023 0.58 2.91 16.799 2.91 16.799 0.765 4.269 4.981 4.316 5.16 4.316h52.531c4.336 0 5.114-4.141 5.146-4.316 0 0 2.929-16.5 2.932-16.868h6.23zm-27.894-5.316c0.642-1.911 2.449-3.293 4.573-3.293 2.126 0 3.932 1.382 4.574 3.293h-9.147zm-42.485-14.62c0-1.273 1.036-2.31 2.31-2.31h55.399c1.274 0 2.311 1.037 2.311 2.31v14.619h-4.021c-0.73-4.275-4.459-7.541-8.94-7.541-4.479 0-8.21 3.265-8.94 7.541h-14.479v-3.423s0-1.7-1.699-1.7h-11.567s-1.699 0-1.699 1.7v3.423h-8.675v-14.619zm6.695 31.326c-2.785 0-5.042-2.257-5.042-5.041 0-2.785 2.257-5.042 5.042-5.042 2.784 0 5.041 2.257 5.041 5.042 0 2.784-2.257 5.041-5.041 5.041zm45.984 0c-2.784 0-5.041-2.257-5.041-5.041 0-2.785 2.257-5.042 5.041-5.042s5.042 2.257 5.042 5.042c0 2.784-2.257 5.041-5.042 5.041zm-33.653 6.027h-3.66v-15.504h3.66v15.504zm6.313 0h-3.66v-15.504h3.66v15.504zm6.283 0h-3.66v-15.504h3.66v15.504zm6.311 0h-3.659v-15.504h3.659v15.504zm6.319 0h-3.66v-15.504h3.66v15.504z' }))], this.template === 'active' ? _createElement('div', {
                'className': 'active-container cmTemplate_active',
                'key': '1718'
            }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle') : null, this.template === 'locked' ? _createElement('div', {
                'className': 'locked-container cmTemplate_locked',
                'key': '1906'
            }, _createElement('span', { 'className': 'model' }, this.vehicleString), _createElement('span', { 'className': 'change' }, 'Change')) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], this.template === 'locked' ? _createElement('div', {
        'className': 'clear-garage cmTemplate_locked',
        'key': '2846'
    }, _createElement('div', {
        'className': 'cm_button',
        'data-cm-role': 'discard-vehicle'
    }, _createElement('span', {}, 'Clear'))) : null);
}
        export const componentNames = ["cm:changeButton"]