const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];

const InitFunc = () => {
  const { Convermax, document } = window;

  Convermax.fitmentSearch = { title: getFitmentSearchTitle() };

  if (document.getElementById('cm_results')) {
    // we need some indication of the search page in terms of selectors to mount the SearchVehicleWidget,
    // otherwise we render it into the "shipping" page title
    document.body.classList.add('cm_search');
  }
};

function getFitmentSearchTitle() {
  const categoryTitle = window.document.querySelector('.page-type-category h1.page-heading');
  if (categoryTitle) {
    return categoryTitle.textContent;
  }

  const brandTitle = window.document.querySelector('.page-type-brand h1.page-heading');
  if (brandTitle) {
    return `${brandTitle.textContent} Parts`;
  }

  return 'parts';
}

const facetPanelIgnoreFields = ['Submodel', 'subcategory'];

export default {
  platform: 'bigcommerce',
  InitFunc,
  SearchRequestDefaults: {
    pageSize: 40,
  },
  product: {
    noImageSrc:
      'https://cdn11.bigcommerce.com/s-ljks1oofuw/stencil/9af562c0-2440-0138-17e4-0242ac11000a/e/42b913a0-3a64-0138-2503-0242ac11000c/img/ProductDefault.gif',
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    getFitmentSearchTitle: () => window.Convermax.fitmentSearch.title,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchBox',
      location: { replace: '#quickSearch .container .form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.user-icons .quicksearch',
        class: 'navUser-item quicksearch mobile',
        wrapper: 'li',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetPanel',
      ignoreFields: facetPanelIgnoreFields,
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: { selector: '.category #faceted-search-container', class: 'cm_facet-panel_container' },
      template: 'FacetPanelContainer',
      ignoreFields: facetPanelIgnoreFields,
    },
    {
      name: 'FacetPanelBrands',
      type: 'FacetPanel',
      location: {
        selector: '.page-type-brand #faceted-search-container',
        class: 'cm_facet-panel_container',
      },
      template: 'FacetPanelContainer',
      ignoreFields: facetPanelIgnoreFields,
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { replace: '.category .page-content .actionBar', class: 'cm_main-content' },
      template: 'MainContent',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: { replace: '.page-type-brand .page-content', class: 'page-content cm_main-content' },
      template: 'MainContent',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select form-select--small',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SingleVehicleGarage',
      location: { insertAfter: '#menu', class: 'cm_single-vehicle-garage cm_desktop' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { insertAfter: 'body.home header.header', class: 'cm_vehicle-widget_container__home' },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      location: {
        replace: ['cm_search', 'page-type-category', 'page-type-brand']
          .map((className) => `body.${className} h1.page-heading`)
          .join(),
      },
      template: 'fitmentSearch/searchVehicleWidgetContainer',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: facetPanelIgnoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'Dialog',
    },
  ],
};
